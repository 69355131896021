.navbar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 200px;
    background-color: #2B2B2B;
    color: #fff;
    padding: 20px;
    overflow-y: auto;
    transition: width 0.3s ease;
    -ms-overflow-style: none; /* para Internet Explorer, Edge */
    scrollbar-width: none; /* para Firefox */
  }

  .navbar::-webkit-scrollbar {
    display: none; /* para navegadores WebKit */
}

/* Opcional: Estilos para cuando el cursor esté sobre la navbar */
.navbar:hover::-webkit-scrollbar {
  display: block;
  width: 8px; /* Ajusta el ancho deseado */
}

.navbar:hover {
  scrollbar-width: thin; /* para Firefox */
  scrollbar-color: #888 #2B2B2B; /* barra y fondo en Firefox */
}

/* Estilos personalizados para el scrollbar */
.navbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Bordes redondeados */
}

.navbar::-webkit-scrollbar-track {
  background: #2B2B2B; /* Color de fondo del track del scrollbar */
}
  
  .navbar.collapsed {
    width: 50px;
  }
  
  .navbar-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .toggle-button {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  .toggle-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .navbar-content {
    /* Estilos para el contenido de la navbar */
  }
  
  .navbar-content ul {
    list-style: none;
    padding: 0;
  }
  
  .navbar-content ul li {
    margin-bottom: 10px;
  }
  
  .navbar-content ul li a {
    display: flex;
    align-items: center;
    padding: 10px;
    text-decoration: none;
    color: #fff;
    background-color: #2B2B2B;
    border-radius: 5px;
  }
  
  .navbar-content ul li a:hover {
    background-color: #34495e;
  }
  
  .button-text {
    margin-left: 10px;
  }
  
  .hidden {
    display: none;
  }
  
  /* Agrega estos estilos */
  .icon-centered {
    margin: auto;
    display: block;
  }
  
  .toggle-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    font-size: 20px; /* Tamaño del icono */
  }
  
  .toggle-button img {
    width: 24px; /* Ajusta el tamaño de la imagen según sea necesario */
  }
  
  .main-content {
    margin-left: 200px; /* Asegúrate de que coincida con el ancho de tu navbar */
    padding: 20px; /* Ajusta según sea necesario */
    transition: margin-left 0.3s ease;
  }
  
  .main-content.collapsed {
    margin-left: 50px; /* Ajusta si el ancho de tu navbar colapsada es diferente */
  }

  .dashboard {
    display: flex;
}

  .contenido {
    margin-left: 200px; /* O el ancho de tu Navbar */
  }
  
  .dashboard-layout {
    display: flex;
}

.dashboard-content {
    margin-left: 230px; /* Ajusta esto según el ancho de tu Navbar */
    width: calc(100% - 200px); /* Esto asegura que el contenido utilice el espacio restante */
    padding: 20px;
}
