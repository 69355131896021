/* AgregarUsuario.css */
.pagina-container {
    
    padding: 20px;
}

.registro-usuario-container {
    max-width: 390px; /* O el ancho que prefieras */
    margin: auto; /* Centrar el contenedor en la página */
    padding: 20px;
    background-color: #2B2B2B; /* O cualquier color de fondo que prefieras */
    border-radius: 8px; /* Opcional, para bordes redondeados */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Opcional, para sombra */
}

.registro-usuario-container h2 {
    text-align: center;
    color: white; /* O el color que prefieras */
}

.registro-usuario-container form {
    display: flex;
    flex-direction: column;
    gap: 2px; /* Espacio entre los campos del formulario */
}

.registro-usuario-container form input[type="text"],
.registro-usuario-container form input[type="password"],
.registro-usuario-container form select {
    padding: 10px;
    border: 1px solid #ccc; /* O cualquier estilo de borde que prefieras */
    border-radius: 4px; /* Para bordes redondeados en los campos de entrada */
}

.registro-usuario-container form input[type="submit"] {
    background-color: #FFA07A; /* O cualquier color de fondo que prefieras */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px; /* Para bordes redondeados en el botón */
    cursor: pointer;
}

.registro-usuario-container form input[type="submit"]:hover {
    background-color: #ff8d60; /* Un tono más oscuro para el hover */
}

html, body {
    overflow-x: hidden; /* Evita el desplazamiento vertical */
}
