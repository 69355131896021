.tareas-listado-container {
    padding: 20px;
    background-color: #2B2B2B;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    color: white;
    margin: 20px auto;
    max-width: 90%;
    overflow-x: hidden; /* Previene el desplazamiento horizontal innecesario */
}

.tareas-tabla {
    width: 100%;
    border-collapse: collapse;
}

.tareas-tabla th, .tareas-tabla td {
    padding: 8px;
    word-wrap: break-word; /* Asegura que el contenido largo se ajuste dentro de la celda */
}

.tareas-tabla th {
    background-color: #FFA07A;
    color: white;
    text-align: center; /* Solo los encabezados centrados */
}

.tareas-tabla td {
    text-align: left; /* Contenido de la celda alineado a la izquierda */
}

.tareas-tabla td.actividad {
    max-width: 250px; /* Establece un máximo para la columna de actividad */
}

.tareas-tabla tr:nth-child(even) {
    background-color: #333;
}

/* Centra los controles de filtro y de fechas */
.tareas-listado-container .fechas-container,
.tareas-listado-container .filtros-container { /* Asume que hay un contenedor para filtros como en el ejemplo */
    display: flex;
    justify-content: center; /* Centra los elementos horizontalmente */
    gap: 10px; /* Espacio entre elementos */
}

.tareas-listado-container input[type="date"],
.tareas-listado-container select {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    color: black;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    min-width: 300px;
    overflow-y: auto; /* Permite desplazamiento vertical dentro del modal si es necesario */
}

.modal-content h2,
.modal-content p,
.modal-content select,
.modal-content button {
    color: #333; /* Color del texto dentro del modal */
}

.modal-content button {
    background-color: #FFA07A;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block; /* Hace que los botones se muestren en bloque, uno debajo del otro */
    width: 100%; /* Asegura que el botón ocupe todo el ancho disponible */
    margin-top: 20px; /* Espacio por encima del primer botón */
}

/* O si usas un identificador */
#encabezado-tareas-asignadas {
    text-align: center;
}