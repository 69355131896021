.autotareas-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
}

.autotareas-form-container {
    width: 100%;
    max-width: 400px; /* Ajusta esto según tus necesidades */
    margin: 0 auto;
    padding: 20px;
    background-color: #2B2B2B;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.autotareas-form-container form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #fff;
}

.form-group select,
.form-group input[type="text"] {
    width: 100%; /* Asegura que el input ocupe todo el ancho del contenedor */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-sizing: border-box; /* Asegura que el padding y border se incluyan en el ancho total */
}

.btn-submit {
    padding: 10px 20px;
    background-color: #FFA07A;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-submit:hover {
    background-color: #ff8d60;
}
