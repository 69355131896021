/* AsignarTarea.css */
.asignar-tarea-container {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ajuste para centrar verticalmente */
    padding: 20px;
}

.asignar-tarea-form-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    background-color: #2B2B2B;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.asignar-tarea-form-container h2 {
    text-align: center;
    color: white;
}

.asignar-tarea-form-container form {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Ajuste para espacio entre campos */
}

.asignar-tarea-form-container form input,
.asignar-tarea-form-container form select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff; /* Fondo blanco para los campos */
    color: #333; /* Texto oscuro para mejor contraste */
}

.asignar-tarea-form-container form input[type="submit"] {
    background-color: #FFA07A;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transición suave para el hover */
}

.asignar-tarea-form-container form input[type="submit"]:hover {
    background-color: #ff8d60;
}
