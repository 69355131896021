.login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* background-color: #f0f2f5; Puedes omitir esta línea si prefieres el fondo de otro contenedor */
}

.login-container {
    /* background: #fff; */
    padding: 40px;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    width: 100%;
}

.login-logo {
    max-width: 100px; /* Ajusta el tamaño de la imagen del logo */
    margin-bottom: 24px;
    animation: spin 5s linear infinite; /* Aplica la animación aquí */
}

.login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form input {
    width: 50%; /* Controla el ancho de los inputs */
    padding: 12px;
    margin: 8px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.login-form button {
    width: 50%; /* Controla el ancho del botón */
    padding: 12px;
    margin-top: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login-form button:hover {
    background-color: #0056b3;
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  